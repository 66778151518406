/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import InputTaskDetails from '../Inputs/InputTaskDetails';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { useDispatch } from 'react-redux';

function RequestDocumentViaLink({
	closeHandler,
	parentFolderId = null,
	clientId = null,
	clientWorkflowName = '',
	editRequest = false,
	inviteLink,
}) {
	const dispatch = useDispatch();
	const [message, setMessage] = useState(inviteLink?.message || '');
	const [loading, setLoading] = useState({
		generateRequest: false,
		updateRequest: false,
	});

	const handleMessageChange = (message) => {
		setMessage(message);
	};

	const handleGenerateLink = async () => {
		setLoading({
			...loading,
			generateRequest: true,
		});
		const payload = {
			parent_folder_id: parentFolderId,
			client_id: clientId,
			client_workflow_name: clientWorkflowName,
			requested_url: `${window.location.origin}/upload-document`,
		};

		try {
			const inviteLink = await dispatch(Actions.getDocumentRequestInviteLink(payload));
			dispatch(Actions.setDocumentRequestInviteLink(inviteLink));

			if (message) {
				await dispatch(
					Actions.updateClientDocumentRequestDetails(
						{ message: message },
						inviteLink?.uuid,
					),
				);
			}

			setLoading({
				...loading,
				generateRequest: false,
			});
			dispatch(Actions.setSnackBarIsOpen(true, true, 'Link generated successfully'));
		} catch (error) {
			setLoading({
				...loading,
				generateRequest: false,
			});
			dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
		}
	};

	const handleInviteLink = async () => {
		setLoading({
			...loading,
			updateRequest: true,
		});
		const payload = {
			message: message || '',
		};

		try {
			await dispatch(Actions.updateClientDocumentRequestDetails(payload, inviteLink?.uuid));
			copyInviteLink('Updated and link copied to clipboard')();
			setLoading({
				...loading,
				updateRequest: false,
			});
		} catch (error) {
			dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			setLoading({
				...loading,
				updateRequest: false,
			});
		}
	};

	const copyInviteLink = (message) => () => {
		navigator.clipboard.writeText(inviteLink?.link || '');
		dispatch(Actions.setSnackBarIsOpen(true, true, message));
	};

	return (
		<>
			<div
				className='content align-items-start'
				style={{
					gap: '24px',
				}}
			>
				<div className='overflow-wrapper '>
					<span className='BodyTwoLight'>
						You can copy the link and include an optional message. The client has 30
						days to upload the file.
					</span>

					<div className='w-100 improved-input-designs'>
						<label>Message (Optional)</label>
						<InputTaskDetails
							richText
							type='text'
							name='description'
							onChange={handleMessageChange}
							value={message}
							style={{
								height: '200px',
							}}
							disabled={!inviteLink}
						/>
					</div>

					{inviteLink?.link && (
						<div
							className='d-flex align-items-end w-100 justify-content-between pb-3'
							style={{ gap: '12px' }}
						>
							<div className='improved-input-designs w-100'>
								<label>Link to Request Files</label>
								<input type='text' disabled placeholder={inviteLink?.link || ''} />
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='d-flex action-buttons w-100 f-flex'>
				<FormButtonOne
					onClick={closeHandler}
					type='button'
					className={'rounded-button-2 secondary'}
				>
					Close
				</FormButtonOne>

				{editRequest ? (
					<div className='d-flex' style={{ gap: '12px' }}>
						<FormButtonOne
							className='rounded-button-2'
							onClick={handleInviteLink}
							type='button'
							loading={loading.updateRequest}
						>
							Update Message
						</FormButtonOne>
						<FormButtonOne
							className='rounded-button-2'
							onClick={copyInviteLink('Link copied to clipboard')}
							type='button'
						>
							Copy Link
						</FormButtonOne>
					</div>
				) : (
					<div className='d-flex' style={{ gap: '12px' }}>
						<FormButtonOne
							className='rounded-button-2'
							onClick={inviteLink?.link ? handleInviteLink : handleGenerateLink}
							type='button'
							loading={
								inviteLink?.link ? loading.updateRequest : loading.generateRequest
							}
						>
							{inviteLink?.link ? 'Copy Link' : 'Generate Link'}
						</FormButtonOne>
					</div>
				)}
			</div>
		</>
	);
}

export default RequestDocumentViaLink;
