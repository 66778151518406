import React from 'react';
import RequestDocumentViaEmail from './RequestDocumentViaEmail';
import RequestDocumentViaLink from './RequestDocumentViaLink';
import FormButtonOne from '../Buttons/FormButtonOne';

function EditRequestDocumentModal({
	inviteLink,
	closeHandler,
	prevReminder = {},
	createNewRequest,
}) {
	return (
		<div className='request-document-modal'>
			<div
				className='d-flex justify-content-between align-items-center w-100'
				style={{ lineHeight: '1.6', padding: '24px 24px 0px 24px' }}
			>
				<div className='HeadlineOneBold dark-text form-title'>Edit Previous Request</div>

				<FormButtonOne
					className='rounded-button-2'
					onClick={createNewRequest}
					type='button'
				>
					Create New Request
				</FormButtonOne>
			</div>

			{inviteLink?.email ? (
				<RequestDocumentViaEmail
					closeHandler={closeHandler}
					prevReminder={prevReminder}
					editRequest={true}
					inviteLink={inviteLink}
				/>
			) : (
				<RequestDocumentViaLink
					closeHandler={closeHandler}
					editRequest={true}
					inviteLink={inviteLink}
				/>
			)}
		</div>
	);
}

export default EditRequestDocumentModal;
