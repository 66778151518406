import { useEffect, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import InputTaskDetails from '../Inputs/InputTaskDetails';
import ToggleButton from '../Inputs/ToggleButton';
import { numberRegex } from '../../utilities/utilities';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';

function RequestDocumentViaEmail({
	closeHandler,
	prevReminder = {},
	parentFolderId = null,
	clientId = null,
	clientWorkflowName = '',
	editRequest = false,
	inviteLink,
}) {
	const dispatch = useDispatch();
	const [reminder, setReminder] = useState(Boolean(prevReminder.reminder));
	const [loading, setLoading] = useState({
		sendInvite: false,
		updateInvite: false,
	});

	const [reminderValues, setReminderValues] = useState({
		total_reminders: prevReminder.total_reminders || 1,
		remind_every: prevReminder.remind_every || 1,
	});
	const [form, setForm] = useState({
		email: inviteLink?.email || '',
		cc: inviteLink?.cc_email?.[0] || '',
		message: '',
	});
	const [error, setError] = useState({
		email: '',
		cc: '',
	});

	const isRequestValid = Boolean(
		form?.email == '' ||
			error?.email !== '' ||
			error.cc !== '' ||
			loading.sendInvite,
	);

	const reminderChangeHanlder = (event) => {
		const { value, name } = event.target;

		if (!numberRegex.test(value)) return;

		setReminderValues({
			...reminderValues,
			[name]: Number(value),
		});
	};

	const handleEmailChange = (event) => {
		const { name, value } = event.target;
		setForm({
			...form,
			[name]: value,
		});

		if (value == '') {
			setError({
				...error,
				[name]: '',
			});
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			setError({
				...error,
				[name]: 'Please enter a valid email',
			});
		} else {
			setError({
				...error,
				[name]: '',
			});
		}
	};

	const handleMessageChange = (message) => {
		setForm({
			...form,
			['message']: message,
		});
	};

	const getInviteLink = async () => {
		setLoading({
			...loading,
			sendInvite: true,
		});
		const payload = {
			parent_folder_id: parentFolderId,
			client_id: clientId,
			client_workflow_name: clientWorkflowName,
			requested_url: `${window.location.origin}/upload-document`,
		};
		dispatch(Actions.getDocumentRequestInviteLink(payload))
			.then((response) => {
				dispatch(Actions.setDocumentRequestInviteLink(response));
				updateAndSendRequest(response);
			})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	const update = () => {
		setLoading({
			...loading,
			updateInvite: true,
		});
		const payload = {
			reminder: reminder,
			email: form?.email,
			cc_email: form?.cc?.length > 0 ? [form?.cc] : [],
			message: form?.message || '',
		};
		if (reminder) {
			payload.remind_every = reminderValues.remind_every;
			payload.total_reminders = reminderValues.total_reminders;
		}
		dispatch(Actions.updateClientDocumentRequestDetails(payload, inviteLink?.uuid))
			.then(() => {
				dispatch(Actions.setSnackBarIsOpen(true, true, 'Updated'));
				setLoading({
					...loading,
					updateInvite: false,
				});
			})
			.catch((error) => {
				setLoading({
					...loading,
					updateInvite: false,
				});
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	};
	const updateAndSendRequest = async (inviteLink) => {
		setLoading({
			...loading,
			sendInvite: true,
		});
		const payload = {
			reminder: reminder,
			email: form?.email,
			cc_email: form?.cc?.length > 0 ? [form?.cc] : [],
			message: form?.message,
		};

		if (reminder) {
			payload.remind_every = reminderValues.remind_every;
			payload.total_reminders = reminderValues.total_reminders;
		}

		const invitePayload = {
			requested_url: `${window.location.origin}/upload-document`,
			uuid: inviteLink?.uuid,
		};
		try {
			await dispatch(Actions.updateClientDocumentRequestDetails(payload, inviteLink?.uuid));
			await dispatch(Actions.sendDocumentUploadInvite(invitePayload));

			setLoading({
				...loading,
				sendInvite: false,
			});
			dispatch(Actions.setSnackBarIsOpen(true, true, 'Email Sent Successfully'));
			closeHandler();
		} catch (error) {
			dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			setLoading({
				...loading,
				sendInvite: false,
			});
		}
	};

	useEffect(() => {
		setForm({
			...form,
			['message']: inviteLink?.message || '',
		});
		return () => {
			setForm({
				email: '',
				cc: '',
			});
			setError({
				email: '',
				cc: '',
			});
		};
	}, [inviteLink]);
	return (
		<>
			<div
				className='content align-items-start'
				style={{
					gap: '24px',
				}}
			>
				<div className='overflow-wrapper '>
					<div className='d-flex flex-column w-100 BodyTwoLight'>
						{`Please enter client's email and an optional message to send an email to
						upload the required document. The client has 30 days to upload the file.`}
					</div>
					<div className='improved-input-designs w-100'>
						<label className='require'>To</label>
						<input
							type='email'
							name='email'
							value={form?.email}
							onChange={handleEmailChange}
							disabled={editRequest}
						/>
						<span className='error-text BodyThreeLight'>{error.email}</span>
					</div>
					<div className='improved-input-designs w-100'>
						<label>Cc</label>
						<input
							type='email'
							name='cc'
							value={form?.cc}
							onChange={handleEmailChange}
							disabled={editRequest}
						/>
						<span className='error-text BodyThreeLight'>{error.cc}</span>
					</div>
					<div className='w-100 improved-input-designs'>
						<label>Message (Optional)</label>
						<InputTaskDetails
							richText
							type='text'
							name='description'
							onChange={handleMessageChange}
							value={form?.message}
							style={{
								height: '200px',
							}}
						/>
					</div>
					<div className='improved-input-designs w-100'>
						<div className='w-100 d-flex justify-content-between'>
							<label>Send Automated Reminder When No File is Uploaded</label>

							<ToggleButton
								checked={reminder}
								onChange={() => setReminder(!reminder)}
							/>
						</div>
					</div>

					{reminder && (
						<div className='w-100 d-flex justify-content-between'>
							<div className='improved-input-designs w-50'>
								<label>Remind Every</label>

								<span
									className='d-flex BodyTwoLight align-items-center'
									style={{ gap: '6px' }}
								>
									<input
										name='remind_every'
										value={reminderValues.remind_every}
										onChange={reminderChangeHanlder}
									/>
									days
								</span>
							</div>

							<div className='improved-input-designs w-50'>
								<label>Number of Reminders</label>
								<input
									name='total_reminders'
									value={reminderValues.total_reminders}
									onChange={reminderChangeHanlder}
								/>
								{editRequest && (
									<div className='d-flex flex-column'>
										<span className='BodyTwoLight mt-1'>
											{prevReminder.cron_reminders} reminder(s) sent
										</span>
										<span className='BodyTwoLight mt-1'>
											{reminderValues.total_reminders -
												prevReminder.cron_reminders}{' '}
											reminder(s) pending
										</span>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='d-flex action-buttons w-100 f-flex'>
				<FormButtonOne
					onClick={closeHandler}
					type='button'
					className={'rounded-button-2 secondary'}
				>
					Close
				</FormButtonOne>

				{editRequest ? (
					<div className='d-flex' style={{ gap: '12px' }}>
						<FormButtonOne
							className='rounded-button-2'
							onClick={update}
							type='button'
							loading={loading.updateInvite}
							disabled={loading.updateInvite}
						>
							Update
						</FormButtonOne>
						<FormButtonOne
							className='rounded-button-2'
							onClick={() => updateAndSendRequest(inviteLink)}
							type='button'
							disabled={isRequestValid || loading.sendInvite}
							loading={loading.sendInvite}
						>
							Send Reminder now
						</FormButtonOne>
					</div>
				) : (
					<div className='d-flex' style={{ gap: '12px' }}>
						<FormButtonOne
							className='rounded-button-2'
							onClick={
								inviteLink ? () => updateAndSendRequest(inviteLink) : getInviteLink
							}
							type='button'
							disabled={isRequestValid || loading.sendInvite}
							loading={loading.sendInvite}
						>
							Send Request
						</FormButtonOne>
					</div>
				)}
			</div>
		</>
	);
}

export default RequestDocumentViaEmail;
