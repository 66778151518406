import { memo, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { Member } from '../../utilities/utilities';
import RequestDocumentModal from './RequestDocumentModal';
import { ClickAwayListener, Modal, Popper } from '@mui/material';
import useModal from '../Modal/useModal';
import PreviousClientDocumentsRequests from './Task Modal/PreviousClientDocumentsRequests';
import { ReactComponent as DownArrow } from '../../assets/icons/Vector.svg';

const ModalTypes = {
	NewRequestModal: 'NewRequestModal',
	PreviousRequestsModal: 'PreviousRequestsModal',
};

function RequestDocumentInvite({
	parentFolderId,
	clientId,
	clientWorkflowName,
	isUserClientsTeamManager,
	isAdhoc,
}) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.authReducer.user);
	const { modalIsOpen, ModalName, ModalType, setModalIsOpen, toggleModal } = useModal();
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const createNewRequest = () => {
		setModalIsOpen(false);
		handleInviteClient();
	};

	const handleInviteClient = () => {
		setAnchorEl(null);
		ModalType(ModalTypes.NewRequestModal);
		setModalIsOpen(true);
	};

	const handlePreviousRequestsClick = () => {
		setAnchorEl(null);
		ModalType(ModalTypes.PreviousRequestsModal);
		setModalIsOpen(true);
	};

	const closeHandler = () => {
		toggleModal();
		dispatch(Actions.setDocumentRequestInviteLink(null));
	};

	return (
		<>
			{(user?.userRole?.id === Member.ownerId ||
				user?.userRole?.id === Member.supervisorId ||
				isUserClientsTeamManager) &&
				!isAdhoc && (
					<>
						<FormButtonOne
							className='rounded-button-1 BodyTwoBold d-flex justify-content-center align-items-center'
							style={{ minWidth: '210px', gap: '4px' }}
							aria-describedby={id}
							type='button'
							onClick={handleClick}
							disabled={!parentFolderId}
						>
							Request Files from Clients <DownArrow />
						</FormButtonOne>

						<Popper
							placement='bottom-end'
							id={id}
							open={open}
							anchorEl={anchorEl}
							style={{ zIndex: 1001 }}
						>
							<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
								<div className='dropdown-box'>
									<span onClick={handleInviteClient}>Create New Request</span>
									<span onClick={handlePreviousRequestsClick}>
										Previous Requests
									</span>
								</div>
							</ClickAwayListener>
						</Popper>
					</>
				)}

			<Modal
				className='d-flex justify-content-center align-items-center'
				open={modalIsOpen}
				onClose={closeHandler}
			>
				<>
					{ModalName == ModalTypes.NewRequestModal && (
						<RequestDocumentModal
							parentFolderId={parentFolderId}
							clientId={clientId}
							clientWorkflowName={clientWorkflowName}
							closeHandler={closeHandler}
						/>
					)}

					{ModalName == ModalTypes.PreviousRequestsModal && (
						<PreviousClientDocumentsRequests
							parentFolderId={parentFolderId}
							clientId={clientId}
							clientWorkflowName={clientWorkflowName}
							closeHandler={closeHandler}
							createNewRequest={createNewRequest}
						/>
					)}
				</>
			</Modal>
		</>
	);
}

export default memo(RequestDocumentInvite);
