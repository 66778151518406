/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormButtonOne from '../../Buttons/FormButtonOne';
import { useDispatch, useSelector } from 'react-redux';
import * as workspaceActions from '../../../redux/actions/dashboard/workspaces-actions';
import { getUserSettings } from '../../../hooks/getUserSettings';
import moment from 'moment';
import SkeletonLoading from '../../Others/SkeletonLoading';
import { truncateString } from '../../../utilities/utilities';
import ReactHtmlParser from 'react-html-parser';
import { Modal, Tooltip } from '@mui/material';
import ViewHtmlParserContent from '../../Modal/ViewHtmlParserContent';
import useModal from '../../Modal/useModal';
import { ReactComponent as EditIcon } from '../../../assets/icons/row-edit.svg';
import * as Actions from '../../../redux/actions/dashboard/workspaces-actions';
import DropDown from '../../Inputs/DropDown';
import { getTimeRange, TIME_RANGE_OPTIONS } from '../../../utilities/TimeRangeUtils';
import NewDateRangePicker from '../../Inputs/NewDateRangePicker';
import { ReactComponent as NewDropDownArrow } from '../../../assets/icons/NewDropDownArrow.svg';
import EditRequestDocumentModal from '../EditRequestDocumentModal';

const MODALS = {
	HTML_CONTENT_MODAL: 'HTMLCONTENTMODAL',
	EDIT_REQUEST_MODAL: 'EDIT_REQUEST_MODAL',
};

const STATUS_OPTIONS = [
	{ value: 'All', label: 'All' },
	{ value: 'Pending', label: 'Pending' },
	{ value: 'Uploaded', label: 'Uploaded' },
];

const DropdownStyle = {
	borderRadius: '16px',
	background: '#FCFDFD',
	height: '30px',
	padding: '6px 0px 6px 12px',
};

function PreviousClientDocumentsRequests({ parentFolderId, closeHandler, createNewRequest }) {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [requestInviteLoading, setRequestInviteLoading] = useState(false);
	const [clientDocumentRequests, setClientDocumentRequests] = useState([]);
	const [selectedRequests, setSelectedRequests] = useState([]);
	const [statusFilter, setStatusFilter] = useState('All');
	const [dateRange, setDateRange] = useState(TIME_RANGE_OPTIONS[2].value);
	const [dateRanges, setDateRanges] = useState(null);

	const allTenantUsers = useSelector((state) => state.userReducer.allTenantUsers);
	const userDetails = useSelector((state) => state.authReducer.userDetails);

	const { date_format } = getUserSettings(userDetails);

	const onTimeChange = (event) => {
		setDateRange(event.target.value);
	};

	const toggleAllSelectedRequests = (event) => {
		if (event.target.checked) {
			setSelectedRequests(
				filteredClientDocumentRequests
					.filter(
						(request) =>
							activeClientDocumentRequests?.includes(request.uuid) && request?.email,
					)
					.map((request) => request.uuid),
			);
		} else {
			setSelectedRequests([]);
		}
	};

	const sendMultipleInvites = async () => {
		setRequestInviteLoading(true);
		try {
			const invitePromises = selectedRequests?.map(async (uuid) => {
				const invitePayload = {
					requested_url: `${window.location.origin}/upload-document`,
					uuid: uuid,
				};
				try {
					await dispatch(Actions.sendDocumentUploadInvite(invitePayload));
				} catch (error) {
					dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
				}
			});

			await Promise.all(invitePromises);
			getRequests();
			setRequestInviteLoading(false);
			dispatch(Actions.setSnackBarIsOpen(true, true, 'Reminder sent successfully'));
		} catch (error) {
			setRequestInviteLoading(false);
			dispatch(Actions.setSnackBarIsOpen(true, true, 'Error sending invites'));
		}
	};

	const checkboxInputHandler = useCallback((uuid) => {
		setSelectedRequests((prevSelectedRequests) => {
			if (prevSelectedRequests.includes(uuid)) {
				return prevSelectedRequests.filter((id) => id !== uuid);
			} else {
				return [...prevSelectedRequests, uuid];
			}
		});
	}, []);

	const getRequests = useCallback(() => {
		setLoading(true);
		dispatch(workspaceActions.getDocumentsRequestByClient(parentFolderId))
			.then((result) => {
				setLoading(false);
				setClientDocumentRequests(result);
			})
			.catch((error) => {
				setClientDocumentRequests([]);
				setLoading(false);
			});
	}, []);

	const filteredClientDocumentRequests = useMemo(() => {
		return clientDocumentRequests.filter((request) => {
			const statusMatches = (() => {
				if (statusFilter === 'All') {
					return true;
				}

				if (statusFilter === 'Pending') {
					return request.status === 'Pending';
				}

				return request.status === 'Submitted' || request.status === 'Fulfilled';
			})();

			const dateMatches = dateRanges
				? moment(request.created_at).isBetween(
						moment(dateRanges.from),
						moment(dateRanges.to),
				  )
				: true;
			return statusMatches && dateMatches;
		});
	}, [clientDocumentRequests, statusFilter, dateRanges]);

	const activeClientDocumentRequests = useMemo(() => {
		return filteredClientDocumentRequests
			.filter((request) => {
				const expireDate = moment(request.expires_at);
				const today = moment();
				return moment(today).isSameOrBefore(expireDate);
			})
			.map((request) => request.uuid);
	}, [filteredClientDocumentRequests]);

	useEffect(() => {
		if (parentFolderId) getRequests();
	}, [parentFolderId, dispatch]);

	useEffect(() => {
		const selectedDateRanges = getTimeRange(dateRange);
		if (selectedDateRanges) setDateRanges(selectedDateRanges);
	}, [dateRange]);

	return (
		<div className='PreviousClientDocumentsRequests'>
			<div className='main'>
				<div className='w-100 d-flex justify-content-between'>
					<SkeletonLoading loading={loading} variant='text' width='20%' height={40}>
						<span className='HeadlineTwoBold dark-text'>
							Request Files from Clients
						</span>
					</SkeletonLoading>

					<div className='d-flex' style={{ gap: '12px' }}>
						<SkeletonLoading
							loading={loading}
							variant='button'
							className='rounded-1'
							width='150px'
							height={40}
						>
							<FormButtonOne
								disabled={selectedRequests?.length == 0 || requestInviteLoading}
								loading={requestInviteLoading}
								onClick={sendMultipleInvites}
								type='button'
								className='rounded-button-2'
							>
								Send Reminder Now
							</FormButtonOne>
						</SkeletonLoading>

						<SkeletonLoading
							loading={loading}
							variant='button'
							className='rounded-1'
							width='150px'
							height={40}
						>
							<FormButtonOne
								onClick={createNewRequest}
								type='button'
								className='rounded-button-2'
							>
								Create New Request
							</FormButtonOne>
						</SkeletonLoading>
					</div>
				</div>

				<div className='w-100 d-flex justify-content-between align-items-center'>
					<SkeletonLoading
						loading={loading}
						variant='button'
						className='rounded-1'
						width='150px'
						height={20}
					>
						<span className='BodyTwoLight'>
							Previous requests sent from all tasks in this workflow :
						</span>
					</SkeletonLoading>

					<div className='d-flex' style={{ gap: '12px' }}>
						<SkeletonLoading
							loading={loading}
							variant='rectangular'
							className='rounded-1'
							width='150px'
							height='32px'
						>
							<div>
								<DropDown
									disableNull
									disableIcon
									normalDropDown
									options={STATUS_OPTIONS}
									onChange={(event) => setStatusFilter(event.target.value)}
									value={statusFilter}
									style={DropdownStyle}
									RenderValue={() => {
										return (
											<DropDownSelectedValue
												label='Upload Status:'
												value={statusFilter}
											/>
										);
									}}
								/>
							</div>
						</SkeletonLoading>

						<SkeletonLoading
							loading={loading}
							variant='rectangular'
							className='rounded-1'
							width='150px'
							height='32px'
						>
							<div className='improved-input-designs text-left'>
								<DropDown
									disableNull
									normalDropDown
									style={DropdownStyle}
									onChange={onTimeChange}
									options={TIME_RANGE_OPTIONS}
									value={dateRange}
									RenderValue={() => {
										return (
											<DropDownSelectedValue
												value={
													TIME_RANGE_OPTIONS.find(
														(opt) => opt.value == dateRange,
													).label
												}
												label='Sent Date:'
											/>
										);
									}}
								/>
							</div>
						</SkeletonLoading>

						{dateRange === TIME_RANGE_OPTIONS[0].value && (
							<SkeletonLoading
								loading={loading}
								variant='rectangular'
								className='rounded-1'
								width='150px'
								height='30px'
							>
								<div className='d-flex' style={{ gap: '24px' }}>
									<NewDateRangePicker
										dateRanges={dateRanges}
										applyDates={(dates) => setDateRanges(dates)}
									/>
								</div>
							</SkeletonLoading>
						)}
					</div>
				</div>

				<div className='table-wrapper'>
					<table>
						<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
							<thead>
								<tr>
									<th>
										<input
											onChange={toggleAllSelectedRequests}
											className='multi-select-checkbox'
											type='checkbox'
											checked={selectedRequests?.length}
										/>
									</th>
									<th>Original Request Sent Date</th>
									<th>User</th>
									<th>To</th>
									<th>Request Type</th>
									<th>Message</th>
									<th>Link Status</th>
									<th>Upload Status</th>
									<th>Reminder Sent</th>
									<th></th>
								</tr>
							</thead>
						</SkeletonLoading>

						<tbody>
							{filteredClientDocumentRequests?.length == 0 && (
								<SkeletonLoading
									loading={loading}
									variant='text'
									width='100%'
									height={45}
								>
									<tr style={{ height: '100%' }}>
										<td className='text-center light-text' colSpan={9}>
											No Requests
										</td>
									</tr>
								</SkeletonLoading>
							)}
							{filteredClientDocumentRequests
								?.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
								?.map((request) => {
									const user = allTenantUsers?.find(
										(u) => u.id == request.requested_by,
									);
									const expireDate = moment(request.expires_at);
									const today = moment();
									const linkStatus = moment(today).isSameOrBefore(expireDate)
										? 'Active'
										: 'Expired';
									const uploadStatus = (() => {
										if (
											request.status == 'Submitted' ||
											request.status == 'Fulfilled'
										)
											return 'Uploaded';

										return request.status;
									})();

									return (
										<Row
											key={request.id}
											date={moment(request.created_at).format(date_format)}
											user={user?.full_name}
											to={request.email}
											message={request.message || ''}
											linkStatus={linkStatus}
											uploadStatus={uploadStatus}
											requestUUid={request.uuid}
											totalReminders={request.total_reminders}
											remindersSent={
												request?.email
													? (request.cron_reminders_sent || 0) +
													  (request.manual_reminders_sent || 0)
													: '-'
											}
											cronReminders={request.cron_reminders_sent || 0}
											remindEvery={request.remind_every}
											cc_email={request.cc_email}
											getRequests={getRequests}
											checkboxInputHandler={checkboxInputHandler}
											checked={Boolean(
												selectedRequests?.find((id) => id == request.uuid),
											)}
											reminder={request.reminder}
											link={request.link}
											loading={loading}
											createNewRequest={createNewRequest}
											requestType={request?.email ? 'Email' : 'Link'}
											disableCheck={Boolean(
												linkStatus === 'Expired' || !request?.email,
											)}
										/>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>

			<div className='ctas'>
				<FormButtonOne
					onClick={closeHandler}
					type='button'
					className={'rounded-button-2 secondary'}
				>
					Close
				</FormButtonOne>
			</div>
		</div>
	);
}

export default PreviousClientDocumentsRequests;

const Row = React.memo(
	({
		date,
		user,
		link,
		to,
		message,
		linkStatus,
		uploadStatus,
		remindersSent,
		requestUUid,
		remindEvery,
		totalReminders,
		cc_email,
		getRequests,
		loading,
		checkboxInputHandler,
		checked,
		reminder,
		createNewRequest,
		cronReminders,
		requestType,
		disableCheck,
	}) => {
		const toolTipTitle = disableCheck
			? linkStatus === 'Expired'
				? 'The link is expired'
				: 'Reminders cannot be sent for requests created via link'
			: '';
		const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

		const requestObject = {
			link: link,
			uuid: requestUUid,
			message: message,
			email: to,
			cc_email: cc_email,
		};

		const reminderObj = {
			reminder: reminder,
			remind_every: remindEvery,
			total_reminders: totalReminders,
			cron_reminders: cronReminders,
		};

		const closeDocumentRequestModal = () => {
			toggleModal();
			getRequests();
		};

		const readMoreClickHanlder = () => {
			ModalType(MODALS.HTML_CONTENT_MODAL);
			toggleModal();
		};

		const editClickHandler = () => {
			ModalType(MODALS.EDIT_REQUEST_MODAL);
			toggleModal();
		};

		return (
			<React.Fragment key={requestUUid}>
				<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
					<tr>
						<td>
							<Tooltip title={toolTipTitle}>
								<input
									checked={checked}
									onChange={() => checkboxInputHandler(requestUUid)}
									className={`${
										disableCheck && 'opacity-50'
									} multi-select-checkbox`}
									type='checkbox'
									disabled={disableCheck}
								/>
							</Tooltip>
						</td>
						<td>{date}</td>
						<td>{user}</td>
						<td>{to}</td>
						<td>{requestType}</td>
						<td>
							<>
								{ReactHtmlParser(truncateString(message || ''))}
								{message?.length > 500 && (
									<span
										onClick={readMoreClickHanlder}
										className='primary-text BodyThreeLight m-0 ml-1 pointer'
									>
										Read More
									</span>
								)}
							</>
						</td>
						<td>{linkStatus}</td>
						<td>{uploadStatus}</td>
						<td>{remindersSent}</td>
						<td>
							<EditIcon
								className={`${linkStatus === 'Expired' ? 'opacity-50' : 'pointer'}`}
								onClick={linkStatus === 'Expired' ? undefined : editClickHandler}
							/>
						</td>
					</tr>

					<Modal
						open={modalIsOpen}
						className='d-flex justify-content-center align-items-center'
					>
						<>
							{ModalName == MODALS.HTML_CONTENT_MODAL && (
								<ViewHtmlParserContent
									title='Message'
									type={'text'}
									value={message}
									edit={false}
									onCancel={() => toggleModal()}
									richTextStyle={{ height: '300px' }}
								/>
							)}

							{ModalName == MODALS.EDIT_REQUEST_MODAL && (
								<>
									<EditRequestDocumentModal
										prevReminder={reminderObj}
										inviteLink={requestObject}
										closeHandler={closeDocumentRequestModal}
										createNewRequest={createNewRequest}
									/>
								</>
							)}
						</>
					</Modal>
				</SkeletonLoading>
			</React.Fragment>
		);
	},
);

Row.displayName = 'Row';

const DropDownSelectedValue = ({ label, value }) => {
	return (
		<span className=' d-flex align-items-center' style={{ gap: '8px' }}>
			<span className=' d-flex align-items-center' style={{ gap: '4px' }}>
				<span className='light-text BodyTwoLight pt-1'>{label}</span>
				<span className='dark-text BodyTwoLight pt-1'>{value}</span>
			</span>

			<NewDropDownArrow />
		</span>
	);
};
