import { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import RequestDocumentViaEmail from './RequestDocumentViaEmail';
import RequestDocumentViaLink from './RequestDocumentViaLink';

const RequestDocumentModal = ({ closeHandler, parentFolderId, clientId, clientWorkflowName }) => {
	const tabs = {
		VIA_EMAIL: 'via_email',
		VIA_LINK: 'via_link',
	};

	const [activeTab, setActiveTab] = useState(tabs.VIA_EMAIL);
	const inviteLink = useSelector((state) => state.workSpaceReducer.documentRequestInviteLink);

	const handleActiveTab = (tab) => () => {
		setActiveTab(tab);
	};

	return (
		<div className='request-document-modal'>
			<div style={{ lineHeight: '1.6', padding: '24px 24px 0px 24px' }}>
				<span className='HeadlineOneBold dark-text form-title'>Create New Request</span>
			</div>
			<div className='active-tabs'>
				<div
					className={`w-50 text-center BodyTwoBold m-0 p-2 pointer ${
						activeTab === tabs.VIA_EMAIL && 'active-tab'
					}`}
					onClick={handleActiveTab(tabs.VIA_EMAIL)}
				>
					Request via Email
				</div>
				<div
					className={`w-50 text-center BodyTwoBold m-0 p-2 pointer ${
						activeTab === tabs.VIA_LINK && 'active-tab'
					}`}
					onClick={handleActiveTab(tabs.VIA_LINK)}
				>
					Request via Link
				</div>
			</div>
			{activeTab == tabs.VIA_EMAIL && (
				<RequestDocumentViaEmail
					closeHandler={closeHandler}
					parentFolderId={parentFolderId}
					clientId={clientId}
					clientWorkflowName={clientWorkflowName}
					inviteLink={inviteLink}
				/>
			)}
			{activeTab == tabs.VIA_LINK && (
				<RequestDocumentViaLink
					closeHandler={closeHandler}
					parentFolderId={parentFolderId}
					clientId={clientId}
					clientWorkflowName={clientWorkflowName}
					inviteLink={inviteLink}
				/>
			)}
		</div>
	);
};

export default memo(RequestDocumentModal);
